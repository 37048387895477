
import Vue from "vue"
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class DialogDivider extends Vue {
    @Prop({required: true})
    public readonly title!: string;
}
